import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "lucide-react";

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  touch-action: pan-y;
`;

const CarouselContent = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translateX(
    calc(
      ${(props) => -props.currentIndex * 100}% - ${(props) => props.offset}px
    )
  );
`;

const CarouselSlide = styled.div`
  width: 100%;
  flex-shrink: 0;
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }
`;

const PrevButton = styled(CarouselButton)`
  color: rgba(52, 17, 26, 1);
  left: 0.5rem;
`;

const NextButton = styled(CarouselButton)`
  right: 0.5rem;
  color: rgba(52, 17, 26, 1);
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

const IndicatorDot = styled.button`
  width: 2%;
  max-width: 3px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "rgba(52, 17, 26, 0.6)" : "rgba(209, 213, 219, 0.6)"};
  border: none;
  cursor: pointer;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

export const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [offset, setOffset] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const carouselRef = useRef(null);
  const slideCount = React.Children.count(children);

  const goToSlide = useCallback((index, jumpImmediately = false) => {
    setIsTransitioning(!jumpImmediately);
    setCurrentIndex(index);
    setOffset(0);
  }, []);

  const nextSlide = useCallback(() => {
    if (isTransitioning) return;
    goToSlide(currentIndex + 1);
  }, [currentIndex, goToSlide, isTransitioning]);

  const prevSlide = useCallback(() => {
    if (isTransitioning) return;
    goToSlide(currentIndex - 1);
  }, [currentIndex, goToSlide, isTransitioning]);

  const handleTouchStart = useCallback((e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      if (!isSwiping) return;
      const currentX = e.touches[0].clientX;
      const diff = startX - currentX;
      setCurrentX(currentX);
      setOffset(diff);
    },
    [isSwiping, startX]
  );

  const handleTouchEnd = useCallback(() => {
    if (!isSwiping) return;
    setIsSwiping(false);
    const diff = startX - currentX;
    const threshold = window.innerWidth * 0.2; // 20% of screen width

    if (Math.abs(diff) > threshold) {
      if (diff > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
    } else {
      setOffset(0);
    }
  }, [isSwiping, startX, currentX, nextSlide, prevSlide]);

  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsTransitioning(false);
      if (currentIndex === 0) {
        goToSlide(slideCount, true);
      } else if (currentIndex === slideCount + 1) {
        goToSlide(1, true);
      }
    };

    const carousel = carouselRef.current;
    carousel.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      carousel.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentIndex, slideCount, goToSlide]);

  const slides = [
    React.cloneElement(children[slideCount - 1]),
    ...React.Children.map(children, (child) => React.cloneElement(child)),
    React.cloneElement(children[0]),
  ];

  return (
    <CarouselWrapper>
      <CarouselContainer
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <CarouselContent
          ref={carouselRef}
          currentIndex={currentIndex}
          offset={offset}
          style={{
            transition: isTransitioning ? "transform 0.3s ease-in-out" : "none",
          }}
        >
          {slides.map((slide, index) => (
            <CarouselSlide key={index}>{slide}</CarouselSlide>
          ))}
        </CarouselContent>

        <PrevButton onClick={prevSlide}>
          <ChevronLeft size={24} />
        </PrevButton>

        <NextButton onClick={nextSlide}>
          <ChevronRight size={24} />
        </NextButton>
      </CarouselContainer>

      <IndicatorContainer>
        {children.map((_, index) => (
          <IndicatorDot
            key={index}
            active={currentIndex === index + 1}
            onClick={() => goToSlide(index + 1)}
          />
        ))}
      </IndicatorContainer>
    </CarouselWrapper>
  );
};
