import styled from "styled-components";
import { getAssetSrc } from "../../utils/getPhotoSrc";
import { Section } from "../Section";
import { Carousel } from "./Carousel";

const ExampleSlide = styled.div`
  display: flex;
  height: 400px;
  // background-color: #f3f4f6;

  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  @media (max-width: 900px) {
    padding: 1rem 1rem 0;
  }
`;

const Image = styled.img`
  width: 70%;
  object-fit: cover;
  border-radius: 20px;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 900px) {
    padding: 1rem;
    text-align: center;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "TheSeasons";
`;

const Description = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const OurStorySection = () => (
  <Section sectionTitle={"Our Story"}>
    <Carousel>
      <ExampleSlide>
        <ImageContainer>
          <Image src={getAssetSrc("Slide_1")} />
        </ImageContainer>
        <TextContainer>
          <Title>Where it all Started</Title>
          <Description>
            It all began at McMaster University, where we actually met on the
            very first day of school. Fun fact: we lived in the same residence
            building and crossed paths during a "meet your neighbors" event on
            orientation day. We hit it off right away and stayed close friends
            throughout undergrad.
          </Description>
        </TextContainer>
      </ExampleSlide>
      <ExampleSlide>
        <ImageContainer>
          <Image src={getAssetSrc("Slide_2")} />
        </ImageContainer>
        <TextContainer>
          <Title>Time Away</Title>
          <Description>
            After graduating from McMaster, Forum left for medical school while
            Kunal moved back to Ottawa to start his career as a software
            engineer at IBM. We stayed close friends and would see each other
            whenever Forum visited home, but for the most part, we took some
            time apart to focus on our careers.
          </Description>
        </TextContainer>
      </ExampleSlide>
      <ExampleSlide>
        <ImageContainer>
          <Image src={getAssetSrc("Slide_3")} />
        </ImageContainer>
        <TextContainer>
          <Title>Back To Toronto</Title>
          <Description>
            In 2022, Forum moved back to Toronto after medical school, and Kunal
            relocated to Toronto to start working at Amazon. We began seeing
            each other more often, we enjoyed trying new restaurants, visiting
            museums, and going for walks along the Toronto Harbour. This is when
            we really began to build our relationship.
          </Description>
        </TextContainer>
      </ExampleSlide>
      <ExampleSlide>
        <ImageContainer>
          <Image src={getAssetSrc("Slide_4")} />
        </ImageContainer>
        <TextContainer>
          <Title>Proposal</Title>
          <Description>
            Since our relationship truly flourished in Toronto, it only made
            sense for Kunal to propose in the city where it all happened. One of
            our favorite dates was walking along the Toronto Harbour, so for the
            proposal, Kunal recreated that special moment!
          </Description>
        </TextContainer>
      </ExampleSlide>
      <ExampleSlide>
        <ImageContainer>
          <Image src={getAssetSrc("Slide_6v2")} />
        </ImageContainer>
        <TextContainer>
          <Title>Gol Dhana</Title>
          <Description>
            With the blessings of Bhagwan, our parents, and loved ones, on May
            20, 2023, our families performed a small, traditional Hindu gol
            dhana engagement ceremony at Forum's family home.
          </Description>
        </TextContainer>
      </ExampleSlide>
      <ExampleSlide>
        <ImageContainer>
          <Image src={getAssetSrc("Slide_5")} />
        </ImageContainer>
        <TextContainer>
          <Title>Here's to Forever</Title>
          <Description>
            With Kunal in Toronto and Forum in New Jersey, we're often asked,
            "What's next?", and the truth is, we're not entirely sure! But what
            we do know is that no matter where life takes us, as long as we keep
            our faith, family, and each other at the center of our lives, our
            home will always be with each other.
          </Description>
        </TextContainer>
      </ExampleSlide>
    </Carousel>
  </Section>
);
