import styled from "styled-components";
import { getAssetSrc } from "../../utils/getPhotoSrc";
import { Section } from "../Section";
import { ProfileCard } from "./ProfileCard";

const ProfileSectionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProfileSection = () => {
  return (
    <Section
      sectionTitle={"Get To Know Us"}
      backgroundColor={"#63484E"}
      titleColor={"#F0ECE8"}
    >
      <ProfileSectionContainer>
        <ProfileCard
          imageSrc={getAssetSrc("KunalAvatar")}
          name="Meet Kunal"
          description={
            "Originally from Ottawa, Ontario, Kunal now lives in downtown Toronto where he works as a software engineer at Amazon.\nIn his spare time, Kunal loves to play board games, watch movies, go downhill skiing and hang out with his friends. His favorite food is a fresh slice of New York thin crust pizza."
          }
        />
        <ProfileCard
          imageSrc={getAssetSrc("ForumAvatar")}
          name="Meet Forum"
          description={
            "Originally from Brampton, Ontario, Forum now lives in New Jersey where she works as a resident doctor with Rutgers University.\nIn her spare time, Forum loves to do the New York Times daily puzzles, listen to music and go for walks. Her favorite food is a hot bowl of spaghetti marinara."
          }
        />
      </ProfileSectionContainer>
    </Section>
  );
};
