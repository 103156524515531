import styled from "styled-components";
import { Section } from "../Section";
import { getPNGAssetSrc } from "../../utils/getPhotoSrc";
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Footer = () => {
  return (
    <Section>
      <FooterContainer>
        <img
          src={getPNGAssetSrc("logoWithNamev2")}
          alt="KF Logo"
          height="100px"
        />
      </FooterContainer>
    </Section>
  );
};
